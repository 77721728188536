import { Typography } from "@mui/material";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";

import { ChartSeriesOptions } from "../Chart/types";
import { ChartTableRow, SeriesForTable } from "./types";
import { MhcConfidenceInterval } from "graphqlApi/types";

/**
 * Modifies series intended for highchart and updates for rendering tabular UI
 *
 * @param series - Series as highcharts expects (ChartSeriesOptions[])
 *
 * @returns Series formatted for table ()
 *
 * @see ChartSeriesOptions
 * @see SeriesForTable
 */
export const seriesForTable = (series: ChartSeriesOptions[]) =>
  series
    .filter(({ id }) => !id?.includes("confidence-interval"))
    .map(({ data = [], color, name, id }) => {
      const dates: SeriesForTable["dates"] = [];
      const values: SeriesForTable["values"] = [];
      data.forEach((d) => {
        if (!isArray(d)) return;
        dates.push(d[0] as number);
        values.push(d[1] as number | null);
      });
      return {
        name,
        dates,
        values,
        color,
        id
      };
    }) as SeriesForTable[];

export const valueRender = ({ value }: ChartTableRow) => {
  if (isNil(value) || value === "") {
    return <Typography color="#757575">N/A</Typography>;
  }
  return <Typography>{value}</Typography>;
};

export const getConfidenceValues = (
  confidenceIntervals: MhcConfidenceInterval[],
  index: number
) => {
  const {
    lower: lowerConfidence,
    upper: upperConfidence,
    weightedFrequency
  } = confidenceIntervals?.[index] ?? {};
  return {
    lowerConfidence,
    upperConfidence,
    weightedFrequency
  };
};
