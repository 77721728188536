import { Box, Stack, SvgIconProps, Typography } from "@mui/material";

import { MhcLocationStat, MhcStatImprovementEnum } from "graphqlApi/types";

import { showImprovingIcon } from "common/components/Icons/utils";
import { capitalizeFirstLetter } from "common/util/helpers";

import { ImprovingIcon, NeedsAttentionIcon } from "common/components/Icons";
import { SecondaryTableCellLabel } from "common/components/IndicatorTable/columns";
import Row from "common/components/Row";

interface ChangeLabelProps {
  needsAttention?: boolean;
  improvementType?: MhcStatImprovementEnum | null;
  maxWidth?: number | string;
  percentageChange?: MhcLocationStat["percentageChange"];
}

export const ChangeLabel = ({
  percentageChange,
  needsAttention,
  improvementType,
  maxWidth
}: ChangeLabelProps) => {
  if (!percentageChange) return null;

  const iconSX: Omit<SvgIconProps, "color"> = {
    width: "20px",
    height: "20px",
    fontSize: "small"
  };

  const [percentageChangeStatement, dateRange] = percentageChange.split(" from ");

  if (!percentageChangeStatement) return null;

  let change = parseFloat(percentageChangeStatement.match(/[-+]?\d+(\.\d+)?/g)?.[0] ?? "0");
  if (percentageChangeStatement.includes("decreased")) {
    change *= -1;
  }

  const iconStyle = {
    mr: 1,
    ml: 0,
    p: 0,
    width: "25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    my: "auto",
    "&:empty": {
      display: { xs: "none", md: "block" }
    }
  };

  const _showImprovingIcon = showImprovingIcon({ change, improvementType });

  return (
    <Row sx={{ maxWidth, textAlign: "left" }}>
      <Stack>
        {needsAttention && (
          <Box sx={iconStyle} className="svg-icon-wrapper">
            <NeedsAttentionIcon {...iconSX} />
          </Box>
        )}
        {_showImprovingIcon && (
          <Box sx={{ ...iconStyle, mr: 0.5 }} className="svg-icon-wrapper">
            <ImprovingIcon {...iconSX} />
          </Box>
        )}
        {!needsAttention && !_showImprovingIcon && (
          <Box sx={iconStyle} className="svg-icon-wrapper" />
        )}
      </Stack>
      <Stack>
        <Typography component="span" variant="body2" sx={{ whiteSpace: "nowrap", fontWeight: 400 }}>
          {capitalizeFirstLetter(percentageChangeStatement)}
        </Typography>
        {dateRange && (
          <SecondaryTableCellLabel sx={{ whiteSpace: "nowrap" }}>
            ({dateRange})
          </SecondaryTableCellLabel>
        )}
      </Stack>
    </Row>
  );
};
