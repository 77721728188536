import { useMemo } from "react";
import { Box, BoxProps, Divider, Stack, StackProps, Typography } from "@mui/material";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

import { StatAttribution, StatAttributionProps } from "./StatAttribution";

export interface StatAttributionListProps extends BoxProps {
  attributions: Array<StatAttributionProps["attribution"]>;
  controlWidth?: boolean;
  itemGap?: StackProps["gap"];
  showDivider?: boolean;
  showTitle?: boolean;
  titleFontWeight?: StatAttributionProps["titleFontWeight"];
}

export const StatAttributionList: React.FC<StatAttributionListProps> = ({
  attributions,
  controlWidth = true,
  itemGap = 4,
  showDivider = true,
  showTitle = true,
  sx,
  titleFontWeight,
  ...props
}) => {
  const sortedAttributions = useMemo(
    () =>
      sortBy(
        uniqBy(attributions, (a) => a.id),
        "name"
      ),
    [attributions]
  );
  if (sortedAttributions.length === 0) return null;
  return (
    <Box
      sx={{
        ...(controlWidth
          ? {
              maxWidth: {
                md: "500px",
                xs: "350px"
              }
            }
          : {}),
        ...sx
      }}
      {...props}
    >
      {showTitle && (
        <>
          <Typography variant="h4" mb={1} component="h5">
            Data Sources
          </Typography>
          {showDivider && <Divider sx={{ mb: 2 }} />}
        </>
      )}
      <Stack gap={itemGap}>
        {sortedAttributions.map((attribution, i) => (
          <StatAttribution
            attribution={attribution}
            key={attribution.id}
            component="li"
            data-testid={`attribution-${i}`}
            titleFontWeight={titleFontWeight}
          />
        ))}
      </Stack>
    </Box>
  );
};
