// TODO: Refactor to not use `styled`
"use client";

import { forwardRef } from "react";
import Close from "@mui/icons-material/Close";
import { ButtonProps, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const CloseButtonBase = styled(IconButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  size: "small",
  padding: 0,
  color: theme.palette.grey[500],
  "&:hover": {
    color: theme.palette.grey[600]
  },
  "&:focus": {
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "2px",
    marginRight: "-2px"
  }
}));

const FCloseButton = forwardRef<HTMLButtonElement | HTMLLinkElement, ButtonProps>((props, ref) => {
  return (
    <CloseButtonBase
      aria-label="close"
      role="button"
      disableRipple={true}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      {...props}
    >
      <Close />
    </CloseButtonBase>
  );
});

FCloseButton.displayName = "CloseButton";

export const CloseButton = FCloseButton;
