import React from "react";
import { Box, FormControlLabel, Link, Stack, Switch, Typography } from "@mui/material";

import { PopoverContainer } from "../PopoverContainer";
import ConfidenceIntervalLegend from "./ConfidenceIntervalLegend";

type ConfidenceIntervalControlsProps = {
  showing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ConfidenceIntervalControls: React.FC<ConfidenceIntervalControlsProps> = ({
  showing,
  onChange
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Box>
        <FormControlLabel
          control={<Switch role="switch" checked={showing} onChange={onChange} />}
          label="Show confidence limits"
        />
        <PopoverContainer
          content={
            <Box p={2.5}>
              <Typography maxWidth="50ch">
                Confidence limits are the numbers at the upper and lower end of a confidence
                interval.
              </Typography>
            </Box>
          }
        >
          <Link component="button">
            <Typography variant="body3">What does this mean?</Typography>
          </Link>
        </PopoverContainer>
      </Box>
      {showing && <ConfidenceIntervalLegend />}
    </Stack>
  );
};

export default ConfidenceIntervalControls;
