import { useParams, usePathname } from "next/navigation";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

type UseRouterPathReturn = {
  /*
    Pathname
    Equal to useRouter().asPath in pages router
  */
  path: string;
  /*
    Path with params as bracketed identifiers (eg `[locationId]`)
    Equal to useRouter().pathname in pages router
  */
  pathWithParamKeys: string;
  /*
    Param object
    Equal to useRouter().query in pages router
  */
  params: Record<string, string | string[]>;
};

const useRouterPath = (): UseRouterPathReturn => {
  const pathname = usePathname();
  const params = useParams();

  if (!pathname) {
    return {
      path: "",
      pathWithParamKeys: "",
      params: {}
    };
  }

  if (!params || isEmpty(params)) {
    return {
      path: pathname,
      pathWithParamKeys: pathname,
      params: {}
    };
  }

  const path = Object.entries(params).reduce(
    (path, [key, value]) =>
      path?.replace((isArray(value) ? value[0] : value) as string, `[${key}]`),
    pathname
  );

  return { path: pathname, pathWithParamKeys: path, params };
};

export default useRouterPath;
