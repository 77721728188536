import { ReactNode } from "react";
import { Box, BoxProps, Stack, Typography, TypographyProps } from "@mui/material";

export type ContentCardTextBlockProps = {
  title?: string;
  sx?: BoxProps["sx"];
  children?: ReactNode;
  variant?: "body1" | "h4" | "h5" | "h6";
  component?: "h3" | "h4" | "h5" | "h6";
  titleProps?: TypographyProps;
};

export const ContentCardTextBlock: React.FC<ContentCardTextBlockProps> = ({
  title,
  sx = { mb: { md: 3 } },
  titleProps = {},
  component = "h5",
  variant = "h4",
  children
}) => {
  const _titleProps = {
    variant,
    component,
    mb: 0.75,
    ...titleProps
  } as TypographyProps;
  return (
    <Box sx={{ flex: 1, ...sx }}>
      {title && <Typography {..._titleProps}>{title}</Typography>}
      <Stack gap={1}>{children}</Stack>
    </Box>
  );
};
