"use client";

import { MhcAttributionFragment } from "graphqlApi/types";

import { PopoverContainer } from "common/components/PopoverContainer";
import { StatAttributionList } from "modules/ECDC/components/StatAttributionList";

interface Props {
  id?: string;
  uiLocation?: string;
  attributions: Array<MhcAttributionFragment>;
  children: React.ReactElement;
}

export const AttributionPopoverBase: React.FC<Props> = ({
  id = "about-the-data-popup",
  attributions,
  uiLocation,
  children
}) => {
  return (
    <PopoverContainer
      label="About the Data (Attributions)"
      uiLocation={uiLocation}
      id={id}
      content={<StatAttributionList attributions={attributions} p={2} />}
    >
      {children}
    </PopoverContainer>
  );
};
