import React, { ReactElement } from "react";
import ScaleIcon from "@mui/icons-material/Scale";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { Stack, Typography } from "@mui/material";

interface LegendItem {
  icon: ReactElement;
  name: string;
}

const LEGEND_ICON_COLOR = "action";
const LEGEND_ITEMS: LegendItem[] = [
  {
    icon: <VerticalAlignBottomIcon fontSize="small" color={LEGEND_ICON_COLOR} />,
    name: "Lower Confidence"
  },
  {
    icon: <VerticalAlignTopIcon fontSize="small" color={LEGEND_ICON_COLOR} />,
    name: "Upper Confidence"
  },
  {
    icon: <ScaleIcon fontSize="small" color={LEGEND_ICON_COLOR} />,
    name: "Weighted Frequency"
  }
];

const ConfidenceIntervalLegendItem: React.FC<LegendItem> = ({ name, icon }) => {
  return (
    <Stack direction="row" gap={0.75} alignItems="center">
      {icon}
      <Typography variant="body3">{name}</Typography>
    </Stack>
  );
};

const ConfidenceIntervalLegend = () => (
  <Stack direction="row" gap={1.25} alignItems="center" data-testid="confidence-interval-legend">
    {LEGEND_ITEMS.map((props) => (
      <ConfidenceIntervalLegendItem {...props} key={props.name} />
    ))}
  </Stack>
);

export default ConfidenceIntervalLegend;
