export const baseColumnIds = ["indicator", "location"];
export const analyticalColumnIds = ["state", "national", "percent_change", "trendline", "target"];
export const columnIdsOptions = [...baseColumnIds, ...analyticalColumnIds];
export const allPossibleColumns = [
  "topic",
  ...baseColumnIds,
  ...analyticalColumnIds,
  "primaryStat",
  "relatedStat"
];
