"use client";

import { forwardRef, useState } from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ButtonProps, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { MhcModal } from "common/components/MhcModal";

const InfoButtonBase = styled(IconButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  size: "small",
  padding: 0,
  color: theme.palette.primary.light,
  "&:hover": {
    color: theme.palette.primary.light
  }
}));

const FInfoButton = forwardRef<HTMLButtonElement | HTMLLinkElement, ButtonProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <InfoButtonBase aria-label="close" disableRipple={true} ref={ref as any} {...props}>
      <InfoOutlined fontSize="small" />
    </InfoButtonBase>
  );
});

FInfoButton.displayName = "InfoButton";

export const InfoButton = FInfoButton;

interface InfoButtonWithModalProps {
  title: string;
  subtitle: string;
  description: string;
}

export const InfoButtonWithModal: React.FC<InfoButtonWithModalProps> = ({
  title,
  subtitle,
  description
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <InfoButton onClick={() => setOpen(true)} />
      <MhcModal
        title={title}
        open={open}
        handleClose={() => setOpen(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <>
          <Typography sx={{ mb: 1 }} fontWeight="700">
            {subtitle}
          </Typography>
          <Typography id="info-modal-description">{description}</Typography>
        </>
      </MhcModal>
    </>
  );
};
