import { ConfidenceColumn } from "./types";

export const CONFIDENCE_COLUMNS: ConfidenceColumn[] = [
  {
    id: "lower-confidence",
    key: "lowerConfidence",
    display: "Lower Confidence"
  },
  {
    id: "upper-confidence",
    key: "upperConfidence",
    display: "Upper Confidence"
  },
  {
    id: "weighted-frequency",
    key: "weightedFrequency",
    display: "Weighted Frequency"
  }
];
