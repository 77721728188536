"use client";

import { ReactNode, useEffect, useMemo, useState } from "react";
import Link from "next/link";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  InputLabel,
  Link as MuiLink,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import uniq from "lodash/uniq";

import { ChartSeriesTableProps, ChartTableRow, DateOption, Value } from "./types";
import { MhcConfidenceInterval } from "graphqlApi/types";

import { CONFIDENCE_COLUMNS } from "./constants";
import { defaultChartColorSet } from "../util/color/config";
import { getConfidenceValues, valueRender } from "./util";
import { formatValueByUnit } from "common/util/formatHelpers";
import { formatDateFromUTC } from "common/util/utcDateFromString";

import { MhcAccordion } from "component/accordion/TextAccordion";
import { DataTable, DataTableProps } from "../../DataTable";
import { genericTableCell } from "../../IndicatorTable/columns/cellContent";
import { ValueOptions } from "../Trendline";

const ChartSeriesTable: React.FC<ChartSeriesTableProps> = ({
  granularity,
  series,
  title,
  subtitle,
  confidenceIntervals = [],
  showConfidenceIntervals = true,
  valueOptions,
  locationId,
  router
}) => {
  const pagePath = router?.asPath;

  // Establish list of dates to display in the dropdown
  const seriesDates = useMemo(() => {
    const dates = uniq(series.flatMap(({ dates }) => dates));
    return dates.sort((a: number, b: number) => {
      if (!a || !b) return 0;
      return b - a;
    });
  }, [series]);

  const dateOptions = useMemo((): DateOption[] => {
    return seriesDates.map((d, i): DateOption => {
      const date = formatDateFromUTC(d, granularity);
      return {
        value: date ?? "",
        label: (i === 0 ? `${date ?? ""} (Most Recent)` : date) ?? "",
        date: d ?? ""
      };
    });
  }, [seriesDates, granularity]);

  // Establish state for the selected date updated by the dropdown
  const [selectedDate, setSelectedDate] = useState<DateOption>(dateOptions[0] as DateOption);

  useEffect(() => {
    if (dateOptions.find(({ value }) => value === selectedDate?.value)) {
      return;
    }
    setSelectedDate(dateOptions[0] as DateOption);
  }, [dateOptions, selectedDate]);

  const formatValue = (value: number | null | undefined, valueOptions?: ValueOptions): Value =>
    valueOptions ? formatValueByUnit({ value, ...valueOptions }) ?? "" : value;

  const [showDatePicker, columns, rows, footnote = ""] = useMemo(() => {
    let columns: DataTableProps<ChartTableRow>["columns"] = [];
    let rows: ChartTableRow[] = [];
    let showDatePicker = false;
    const footnote = "";
    const valueLabel = subtitle ?? series[0]?.valueLabel ?? "Value";
    let valueLabelDisplay: ReactNode = "";
    if (valueLabel.includes("rate per")) {
      valueLabelDisplay = (
        <Stack>
          <Typography fontWeight={600}>Rate</Typography>
          <Typography variant="body2">{valueLabel.replace("rate", "")}</Typography>
        </Stack>
      );
    } else {
      valueLabelDisplay = <Typography fontWeight={600}>{valueLabel}</Typography>;
    }
    const hasConfidenceIntervals = showConfidenceIntervals && confidenceIntervals?.length;
    let confidenceIntervalColumns: DataTableProps<ChartTableRow>["columns"] = [];
    if (hasConfidenceIntervals) {
      confidenceIntervalColumns = CONFIDENCE_COLUMNS.map(({ id, key, display }) =>
        genericTableCell({
          id,
          align: "right",
          display,
          render: (row) => {
            return <Typography textAlign="right">{row[key]?.toLocaleString()}</Typography>;
          }
        })
      );
    }
    // If more than one series show the date picker and render rows for each series
    if (series.length > 1) {
      showDatePicker = true;
      columns = [
        genericTableCell({
          id: "name",
          align: "left",
          display: <Typography fontWeight={600}>{title}</Typography>,
          render: ({ name, index, color }) => {
            return (
              <Typography display="flex" gap={1} alignItems="center">
                <CircleIcon sx={{ color: (color as string) ?? defaultChartColorSet[index ?? 0] }} />{" "}
                {name}
              </Typography>
            );
          }
        }),
        genericTableCell({
          id: "value",
          align: "right",
          display: valueLabelDisplay,
          render: valueRender
        }),
        ...confidenceIntervalColumns
      ];
      rows =
        series.map(({ color, dates, name: seriesName, values, id }, index): ChartTableRow => {
          const _seriesDates: (string | number)[] = dates ?? [];
          const pointIndex = _seriesDates.indexOf(selectedDate?.date);
          const seriesConfidenceIntervals =
            (confidenceIntervals?.[index] as MhcConfidenceInterval[]) ?? [];
          const confidenceValues =
            showConfidenceIntervals && seriesConfidenceIntervals?.length
              ? getConfidenceValues(seriesConfidenceIntervals, pointIndex)
              : {};
          let name: ChartTableRow["name"] = seriesName;
          if (locationId && id && locationId !== id && pagePath) {
            name = (
              <MuiLink href={pagePath.replace(locationId, id)} component={Link}>
                {name}
              </MuiLink>
            );
          }
          return {
            id: typeof name === "string" ? name : undefined ?? id ?? "",
            index,
            color: color ?? defaultChartColorSet[index],
            name,
            value: valueOptions
              ? formatValue(values[pointIndex], valueOptions)
              : values[pointIndex],
            ...confidenceValues
          };
        }) ?? [];
    }
    // If only one series do not show the date picker and render rows for each date
    else {
      columns = [
        genericTableCell({
          id: "date",
          align: "left",
          display: title,
          render: ({ date }) => {
            return <Typography>{date}</Typography>;
          }
        }),
        genericTableCell({
          id: "value",
          align: "right",
          display: valueLabelDisplay,
          render: valueRender
        }),
        ...confidenceIntervalColumns
      ];
      rows = dateOptions.map(({ date, value }) => {
        const { name = "", dates, values = [] } = series[0] ?? {};
        const _seriesDates: (string | number)[] = dates ?? [];
        const pointIndex = _seriesDates.indexOf(date);
        const seriesConfidenceIntervals =
          (confidenceIntervals?.[0] as MhcConfidenceInterval[]) ?? [];
        const confidenceValues =
          showConfidenceIntervals && seriesConfidenceIntervals?.length
            ? getConfidenceValues(seriesConfidenceIntervals, pointIndex)
            : {};
        return {
          id: `${name} ${date}`.toLowerCase().replace(" ", "-"),
          index: 0,
          color: "",
          name,
          date: value,
          value: formatValue(values[pointIndex], valueOptions),
          ...confidenceValues
        };
      });
    }
    return [showDatePicker, columns, rows, footnote];
  }, [
    confidenceIntervals,
    dateOptions,
    locationId,
    pagePath,
    selectedDate,
    series,
    showConfidenceIntervals,
    subtitle,
    title,
    valueOptions
  ]);

  const hasOnlyOneSeries = series.length === 1;

  return (
    <MhcAccordion
      title={
        <Typography>
          <Typography fontWeight={700} component="strong">
            Data Table
          </Typography>
          {title && ` for ${title}`}
        </Typography>
      }
      uiLocation={"With Chart"}
      variant={"blue"}
      defaultOpen={true}
      id="chart-table"
      contentWrapperSx={{ p: hasOnlyOneSeries ? undefined : 4 }}
    >
      <Stack gap={3}>
        {showDatePicker && (
          <FormControl>
            <InputLabel id="series-table-date-selector">Select Year</InputLabel>
            <Select
              labelId="series-table-date-selector"
              value={selectedDate?.value ?? ""}
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              label="Select Year"
              onChange={(event) =>
                setSelectedDate(
                  dateOptions.find(({ value }) => value === event.target.value) as DateOption
                )
              }
              data-testid="menu"
              role="menu"
            >
              {dateOptions.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Stack gap={1}>
          {rows.length > 0 && (
            <DataTable data={rows} columns={columns} removeBorders={hasOnlyOneSeries} />
          )}
          {footnote && (
            <Typography variant="body2" textAlign="right">
              {footnote}
            </Typography>
          )}
        </Stack>
      </Stack>
    </MhcAccordion>
  );
};

export default ChartSeriesTable;
